import hmacSHA256 from "crypto-js/hmac-sha256";
import Base64 from "crypto-js/enc-base64";
import axios from "axios";
import _ from "lodash";
import { API_SECRET } from "../config";

export function isJsonString(str) {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }
  return true;
}

export function getHashKey(obsText, time_in_ms) {
  const api_secret = API_SECRET;

  let message = `${time_in_ms}|${_.join(obsText, "|")}`;

  let hmac = hmacSHA256(message, api_secret);
  //   hmac.write(message); // write in to the stream
  //   hmac.end(); // can't read from the stream until you call end()
  const hash = Base64.stringify(hmac);

  return hash;
}

export async function getLocationFromIP() {
  const url = "https://am.i.mullvad.net/json";
  try {
    let ipGeo = await axios.get(url);
    ipGeo = ipGeo?.data;
    return ipGeo;
    // let revLocation = await axios.get(url);
    // revLocation = revLocation?.data;
    // const coords = { latitude: revLocation?.ll[0], longitude: revLocation?.ll[1] };
    // console.log('rev location is ---------', revLocation);
  } catch (err) {
    console.log("err is", err);
    return null;
  }
}
