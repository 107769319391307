import PropTypes from 'prop-types';
// material
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box
      component="img"
      // src={JSON.parse(localStorage.getItem('companyMeta'))?.companyLogo || '/favicon/karmaFavicon.png'}
      src="/favicon/karmaFavicon.png"
      sx={{ height: 'auto', ...sx }}
    />
  );
}
