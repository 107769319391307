export const BASE_URL = process.env.REACT_APP_BASE_URL;
// export const BASE_URL= 'https://c38de667-df93-4e3b-ad96-9b53084c2545.mock.pstmn.io/api';

export const whatsApp = {
  url: process.env.REACT_APP_WHATS_APP_URI,
  number: process.env.REACT_APP_WHATS_APP_SUPPORT_NUMBER,
};

export const API_KEY = process.env.REACT_APP_API_KEY;
export const API_SECRET = process.env.REACT_APP_API_SECRET;
