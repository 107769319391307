import { createContext, useEffect, useReducer } from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import axios from "utils/axios";
import origAxios from "axios";
import { BASE_URL } from "../config";
import { localStorageKey } from "utils/constants/common";
// import { mockResponses } from "./mockResponse";
import { isJsonString } from "utils/common";

const url = {
  initiateOneClickCreditSetupApi: "credit/user/InitiateOneClickCreditSetup",
  getOneClickCreditSetup: "credit/user/GetOneClickCreditSetup",
  getTermsAndCondition: "common/misc/GetTermsAndCondition",
  acceptTermsAndCondition: "common/misc/AcceptTermsAndCondition",
};

// const baseURL = "http://localhost:56576";

// http://localhost:3000/?phone=910000000021&name=Jitin&partner=TaskMo&deviceId=test1&isWhatsAppContact=true&lat=28.6869982&long=77.1392035&hash=9qhxxdKIZyv8h%2FLo%2FPhf2GZpisV2fxcYL6bKN6D40oo%3D&time=1663843921683&api=NjJlN2Y4MmMzZjc3NDMzYmYwODUxZTFiOnRlc3Qta2V5MQ%3D%3D

const initialState = {
  isInitialized: true,
  creditSetupResponse: {},
  termsAndConditions: {},
  errorParams: {},
};

const handlers = {
  INITIALIZE: (state, action) => {
    return {
      ...state,
      isInitialized: true,
      creditSetupResponse: {},
    };
  },
  CREDIT_SETUP_STATUS: (state, action) => {
    return {
      ...state,
      creditSetupResponse: action.payload,
    };
  },
  TERMS_AND_CONDITION: (state, action) => {
    return {
      ...state,
      termsAndConditions: action.payload,
    };
  },
  ERROR_PARAMS: (state, action) => {
    return {
      ...state,
      errorParams: action.payload,
    };
  },
};

const reducer = (state, action) =>
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AppContext = createContext({
  ...initialState,
  method: "app",
  initiateOneClickCreditSetupApi: () => Promise.resolve(),
  getOneClickCreditSetupApi: () => Promise.resolve(),
  getTermsAndConditionApi: () => Promise.resolve(),
  acceptTermsAndConditionApi: () => Promise.resolve(),
  initializeCreditResponse: () => {},
  setErrorParams: () => {},
});

AppProvider.propTypes = {
  children: PropTypes.node,
};

function AppProvider({ children }) {
  const [state, dispatch] = useReducer(reducer, initialState);

  const initializeCreditResponse = () => {
    dispatch({
      type: "INITIALIZE",

      // payload: mockResponses.creditStatusReponse
    });
  };

  const initiateOneClickCreditSetupApi = async () => {
    try {
      const userInfo = localStorage.getItem(localStorageKey.userInfo);

      const userInfoObj = JSON.parse(userInfo);
      const params = {
        phone: userInfoObj.phone,
        partner_name: userInfoObj?.partner_name,
        partner: userInfoObj?.partner_id,
        device_id: userInfoObj?.device_id,
        geo_location: {
          latitude: Number(userInfoObj?.lat) || 0,
          longitude: Number(userInfoObj?.long) || 0,
        },
        is_allowed_to_contact_on_whatsapp: !!userInfoObj?.is_whatsapp_contact
          ? "YES"
          : "NO",
        enable_streamed_response: false,
      };

      const response = await origAxios.post(
        `${BASE_URL}/${url.initiateOneClickCreditSetupApi}`,
        params,
        {
          headers: {
            "X-App-Key": userInfoObj?.app_key,
            "X-Req-Time": userInfoObj?.time_in_ms,
            "X-Req-Hash": userInfoObj?.hash,
          },
        }
      );
      if (response.data) {
        dispatch({
          type: "CREDIT_SETUP_STATUS",
          payload: response.data,
          // payload: mockResponses.creditStatusReponse
        });
        localStorage.setItem(
          localStorageKey.creditUserId,
          response?.data?.user_id
        );

        localStorage.setItem(
          localStorageKey.authToken,
          response?.data?.auth_token
        );
        return response.data;
      }
    } catch (err) {
      console.log("err", err);
      let errMsg =
        err?.response?.data?.message ||
        err?.error?.message ||
        "Something went wrong. Please try again later";

      setErrorParams(
        !!err?.error?.message ? "Something went wrong" : "Server Issue",
        errMsg
      );
    }
  };

  const getOneClickCreditSetupApi = async (params) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${url.getOneClickCreditSetup}`,
        params
      );
      if (response.data) {
        dispatch({
          type: "CREDIT_SETUP_STATUS",
          payload: response.data?.result,
        });
        if (response?.data?.result?.user_id)
          localStorage.setItem(
            localStorageKey.creditUserId,
            response?.data?.result?.user_id
          );

        return response.data?.result;
      }
    } catch (err) {
      console.log("err", err);
      let errMsg =
        err?.response?.data?.message ||
        err?.error?.message ||
        "Something went wrong. Please try again later";

      setErrorParams(
        !!err?.error?.message ? "Something went wrong" : "Server Issue",
        errMsg
      );
    }
  };

  const getTermsAndConditionApi = async (params) => {
    try {
      const response = await axios.post(
        `${BASE_URL}/${url.getTermsAndCondition}`,
        params
      );
      if (response.data) {
        dispatch({
          type: "TERMS_AND_CONDITION",
          payload: response.data,
        });
        return response.data;
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      let errMsg =
        err?.response?.data?.message ||
        err?.error?.message ||
        "Something went wrong. Please try again later";

      setErrorParams(
        !!err?.error?.message ? "Something went wrong" : "Server Issue",
        errMsg
      );
    }
  };

  const acceptTermsAndConditionApi = async (params) => {
    try {
      let userInfo = localStorage.getItem(localStorageKey.userInfo);
      if (isJsonString(userInfo)) {
        userInfo = JSON.parse(userInfo);
      }

      const response = await axios.post(
        `${BASE_URL}/${url.acceptTermsAndCondition}`,
        params,
        {
          headers: { "X-Device-Id": userInfo?.device_id || "" },
        }
      );
      if (response.data) {
        return response.data;
      } else {
        throw new Error("Something went wrong");
      }
    } catch (err) {
      let errMsg =
        err?.response?.data?.message ||
        err?.error?.message ||
        "Something went wrong. Please try again later";

      setErrorParams(
        !!err?.error?.message ? "Something went wrong" : "Server Issue",
        errMsg
      );
    }
  };

  const setErrorParams = (label, content) => {
    let params = {};
    if (label) {
      params = { label, content };
    }
    dispatch({
      type: "ERROR_PARAMS",
      payload: params,
    });
  };

  return (
    <AppContext.Provider
      value={{
        ...state,
        method: "jwt",
        initiateOneClickCreditSetupApi,
        getOneClickCreditSetupApi,
        getTermsAndConditionApi,
        acceptTermsAndConditionApi,
        initializeCreditResponse,
        setErrorParams,
      }}
    >
      {children}
    </AppContext.Provider>
  );
}

export { AppContext, AppProvider };
