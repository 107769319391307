import { useLayoutEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import _ from 'lodash';
// material
import { styled } from '@mui/material/styles';
//
import PayoutNavbar from './PayoutNavbar';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
}));
const APP_BAR_MOBILE = 64;
const MainStyle = styled('div')(() => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  paddingLeft: 24,
  paddingRight: 24,
  paddingTop: APP_BAR_MOBILE
}));

// ----------------------------------------------------------------------

export default function PayoutLayout() {


  // useLayoutEffect(() => {
  //   if (!_.isEmpty(userKycDetails) && userKycDetails?.is_kyc_done) {
  //     navigate(PATH_LOC_DASHBOARD.home, { replace: true });
  //   }
  // }, [userKycDetails]);
  return (
    <>
      <RootStyle>
        <PayoutNavbar />

        <MainStyle>
          <Outlet />
        </MainStyle>
      </RootStyle>
    </>
  );
}
