import PropTypes from "prop-types";
import React, { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import useLocales from "hooks/useLocales";

// material
import { styled } from "@mui/material/styles";
import useScrollTrigger from "@mui/material/useScrollTrigger";
import {
  AppBar,
  Toolbar,
  Typography,
  IconButton,
  Stack,
  Menu,
  MenuItem,
} from "@mui/material";
import { PATH_PAYOUT, ROOT_PAYOUT, PATH_SUCCESS } from "routes/path";
import HelpIcon from "pages/components/HelpIcon";

// components
//

// import NotificationsPopover from './NotificationsPopover';
// import AccountPopover from './AccountPopover';

// ----------------------------------------------------------------------

const APPBAR_MOBILE = 64;

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 2 : 0,
    sx: {
      backgroundColor: trigger ? "white" : "transparent",
      transition: "all 0.2s ease",
    },
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};

const RootStyle = styled(AppBar)(({ theme }) => ({
  // backgroundColor: 'transparent',
  // boxShadow: '0 2px 4px 0 rgba(0,0,0,0.1)',
  height: 64,
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  justifyContent: "space-between",
  padding: "0px 24px",
}));

// ----------------------------------------------------------------------

PayoutNavbar.propTypes = {
  onOpenSidebar: PropTypes.func,
};

export default function PayoutNavbar(props) {
  const { translate } = useLocales();
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const { pathname } = useLocation();
  // const pathName = window.location.pathname?.split('/').pop();

  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtSupport = () => {
    const phone = localStorage.getItem("phone");
    // let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    // let text = kycHelpConst.KYC;
    handleMenuClose();
    switch (pathname) {
      default:
        break;
    }
    // whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    // window.open(whatsAppUrl, '_blank').focus();
  };

  const getHeader = () => {
    let header = "Hi";
    switch (pathname) {
      case PATH_PAYOUT.loanAgreement:
        header = "Hi";
        break;
      case PATH_PAYOUT.processing:
        header = "Hi";
      case PATH_SUCCESS:
        header = "Hi";

        break;
      default:
        break;
    }
    return header;
  };

  const showHelpIcon = () => {
    return false;
    // switch (pathname) {
    //   case PATH_PAYOUT.loanAgreement:
    //     return false;
    //   case PATH_PAYOUT.processing:
    //     return false;
    //   case PATH_PAYOUT.userConsent:
    //     return false;
    //   case PATH_SUCCESS:
    //     return false;

    //   default:
    //     return false;
    // }
  };

  return (
    <>
      <Menu
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
      >
        <MenuItem onClick={onBtSupport}>
          {translate("common_contact_support_label")}{" "}
        </MenuItem>
      </Menu>
      <ElevationScroll {...props}>
        <RootStyle position="fixed">
          <ToolbarStyle>
            <Stack
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              {/* <IconButton edge="start" aria-label="back arrow" sx={{ mr: 1, color: 'black' }} onClick={handleBackClick}>
                <ArrowBackIcon />
              </IconButton> */}
              {/* <Typography
                sx={{ color: "black", fontWeight: 500, fontSize: 20 }}
                component="div"
              >
                {getHeader()}
              </Typography> */}
            </Stack>
            {showHelpIcon() && <HelpIcon />}
          </ToolbarStyle>
        </RootStyle>
      </ElevationScroll>
    </>
  );
}
