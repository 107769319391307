import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import _ from "lodash";
import useInterval from "hooks/useInterval";
import { Box, Typography, Stack, IconButton, Divider } from "@mui/material";
import PaperBox from "components/PaperBox";
import { keyframes } from "@emotion/react";
import CustomSubmitButton from "components/CustomSubmitButton";
import useApp from "hooks/useApp";
import { localStorageKey } from "utils/constants/common";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PATH_EXIT_PENDING, PATH_PAYOUT, PATH_SUCCESS } from "routes/path";

const rotationEffect = keyframes`
from {
  transform: rotate(0deg);
}
to {
  transform: rotate(359deg);
}
`;

const RootStyle = styled(Box)(({ theme }) => ({
  display: "flex",
  minHeight: "85vh",
  flexDirection: "column",
  justifyContent: "space-between",
}));

export default function PayoutProcessingPage() {
  const [isPollingExit, setPollingExit] = useState(false);
  const navigate = useNavigate();
  const { creditSetupResponse, getOneClickCreditSetupApi } = useApp();
  const userInfo = localStorage.getItem(localStorageKey.userInfo);

  useEffect(() => {
    setTimeout(() => {
      setPollingExit(true);
    }, 5000);
  }, []);

  useInterval(
    async () => {
      try {
        if (!_.isEmpty(userInfo)) {
          const userInfoObj = JSON.parse(userInfo);
          const params = {
            enable_streamed_response: false,
            phone: userInfoObj.phone,
          };
          getOneClickCreditSetupApi(params);
        }
      } catch (err) {
        console.log("err is", err);
      }
    },
    15000,
    isPollingExit
  );

  useEffect(() => {
    if (creditSetupResponse?.is_consent_required) {
      // navigate to get terms and condition api
      navigate(PATH_PAYOUT.userConsent, { replace: true });
      return;
    }

    if (creditSetupResponse?.setup_status === "COMPLETED") {
      navigate(PATH_SUCCESS, { replace: true });
    }
  }, [creditSetupResponse]);

  const onBtExit = () => {
    localStorage.clear();
    // window.open("about:blank", "_self");
    // window.close();
    navigate({
      pathname: PATH_EXIT_PENDING,
      search: createSearchParams({
        reason: creditSetupResponse?.setup_status,
      }).toString(),
      replace: true,
    });
  };

  return (
    <>
      <RootStyle title="Plan Details | Karmalife">
        <PaperBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            minHeight: "70vh",
          }}
        >
          {/* <Box
            component="img"
            src="/images/fetchingLogo.svg"
            sx={{ animation: `${rotationEffect} 1s infinite linear` }}
          /> */}
          <Box
            sx={{ position: "relative", minWidth: "200px", minHeight: "112px" }}
          >
            <Box
              component="img"
              src="/images/loaderBackground.png"
              sx={{ width: "100%", position: "absolute" }}
            />
            <Box
              component="img"
              src="/images/fetchingLogo.svg"
              sx={{
                animation: `${rotationEffect} 1s infinite linear`,
                margin: "auto",
              }}
            />
          </Box>
          <Typography
            sx={{
              fontSize: "14px",
              color: "#6E6F71",
              mt: 3,
              textAlign: "center",
            }}
          >
            Look like its taking longer than expected, it will be ready in 5
            mins
          </Typography>
        </PaperBox>
        <CustomSubmitButton
          label="Exit"
          // isLoading={isLoading}
          onClick={onBtExit}
          variant="contained"
          sx={{ marginTop: "auto", position: "relative" }}
        />
        <Typography
          sx={{
            fontSize: "12px",
            color: "#919191",
            fontWeight: 500,
            lineHeight: "14px",
            mt: 1,
            position: "absolute",
            bottom: "4px",
            right: "24px"
          }}
        >
         V: {`${window?.env?.VERSION_NAME}(${window?.env?.VERSION_CODE})`}
        </Typography>
      </RootStyle>
    </>
  );
}
