export const localStorageKey = {
  i18nextLng: "i18nextLng",
  isLangSelected: "isLangSelected",
  accessToken: "accessToken",
  userInfo: "userInfo",
  creditUserId: "creditUserId",
  authToken: "refreshToken",
  phone: 'phone'
};

export const pendingStepConst = {
  OTHER: "OTHER",
  PARTNER_EMP_FETCH: "PARTNER_EMP_FETCH",
  USER_SIGNIN: "USER_SIGNIN",
  UNLOCK: "UNLOCK",
  KYC: "KYC",
  BANK_ACCOUNT: "BANK_ACCOUNT",
  ONBOARD_OTHER: "ONBOARD_OTHER",
  NBFC_ACC_NOT_DONE: "NBFC_ACC_NOT_DONE",
  TANSACTION_NOT_READY: "ACCOUNT_NOT_READY_FOR_TRANSACTION",
};

export const stepStatusConst = [
  {
    pending_step: "OTHER",
    step_count: 0,
    step_per: 0,
    step_desc: "Initializing",
  },
  {
    pending_step: "PARTNER_EMP_FETCH",
    step_count: 1,
    step_per: 11,
    step_desc: "Fetching your details",
  },
  {
    pending_step: "USER_SIGNIN",
    step_count: 2,
    step_per: 22,
    step_desc: "Signing you in",
  },
  {
    pending_step: "UNLOCK",
    step_count: 3,
    step_per: 33,
    step_desc: "Unlocking your offer",
  },
  {
    pending_step: "KYC",
    step_count: 4,
    step_per: 40,
    step_desc: "Completing your KYC",
  },
  {
    pending_step: "BANK_ACCOUNT",
    step_count: 5,
    step_per: 50,
    step_desc: "Setting up transfer details",
  },
  {
    pending_step: "ONBOARD_OTHER",
    step_count: 6,
    step_per: 60,
    step_desc: "Processing",
  },
  {
    pending_step: "NBFC_ACC_NOT_DONE",
    step_count: 7,
    step_per: 80,
    step_desc: "Creating your account",
  },
  {
    pending_step: "ACCOUNT_NOT_READY_FOR_TRANSACTION",
    step_count: 8,
    step_per: 90,
    step_desc:
      "Look like its taking longer than expected, it will be ready in 5 mins",
  },
];
