import { useEffect, useState } from "react";
import { styled } from "@mui/material/styles";
import { Box, Stack, IconButton, Divider, Typography } from "@mui/material";
import PaperBox from "components/PaperBox";
import Page from "components/Page";
import ExitToAppIcon from "@mui/icons-material/ExitToApp";

const RootStyle = styled(Page)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  // alignItems: "center",
  justifyContent: "center",
  minHeight: "100vh",
  padding: "24px",
  // position: "relative"
}));

export default function ExitPage() {
  return (
    <>
      <RootStyle title="Onboard setup | Karmalife">
        <PaperBox
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",

            p: "23px",
            minHeight: "438px",
            // mt: "60px",
          }}
        >
          <ExitToAppIcon sx={{ fontSize: "150px", opacity: ".8" }} />

          <Typography
            sx={{
              fontSize: "12px",
              color: "#6E6F71",
              mt: 2,
            }}
          >
            Please Close the App.
          </Typography>
        </PaperBox>
        <Typography
          sx={{
            fontSize: "12px",
            color: "#919191",
            fontWeight: 500,
            lineHeight: "14px",
            mt: 1,
            position: "absolute",
            bottom: "4px",
            right: "24px"
          }}
        >
         V: {`${window?.env?.VERSION_NAME}(${window?.env?.VERSION_CODE})`}
        </Typography>
      </RootStyle>
    </>
  );
}
