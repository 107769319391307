import LoanAgreement from "pages/PayoutProcessingPage";

function path(root, sublink) {
    return `${root}${sublink}`;
  }

 export const ROOT_PAYOUT = '/payout';

  export const PATH_PAYOUT = {
    root: ROOT_PAYOUT,
    onboard: path(ROOT_PAYOUT, '/onboard'),
    loanAgreement: path(ROOT_PAYOUT, '/loan-agreement'),
    userConsent: path(ROOT_PAYOUT, '/consent'),
    processing: path(ROOT_PAYOUT, '/processing')
  }

export const PATH_SUCCESS = '/success';
export const PATH_EXIT_SUCCESS = '/exit/success';
export const PATH_EXIT_PENDING = '/exit/pending';
export const PATH_EXIT_FAILURE = '/exit/failure';