import axios from "axios";
import axiosRetry from "axios-retry";
// import * as serviceWorkerRegistration from 'serviceWorkerRegistration';
import { localStorageKey } from "utils/constants/common";
import { getHashKey } from "utils/common";
import { BASE_URL, API_KEY } from "../config";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 60000,
});
// axiosInstance.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject((error.response && error.response.data) || 'Something went wrong')
// );
axiosInstance.interceptors.request.use((config) => {
  const reqData = config.data;
  const obsText = [];
  const token = localStorage.getItem(localStorageKey.authToken);

  if (reqData?.phone) {
    const phone =
      reqData?.phone?.length === 12 ? reqData?.phone : `91${reqData?.phone}`;
    obsText.push(phone);
  }
  if (reqData?.partner) {
    obsText.push(reqData?.partner);
  }
  if (reqData?.user_id) {
    obsText.push(reqData?.user_id);
  }
  const time_in_ms = new Date().getTime();

  const hashedKey = getHashKey(obsText, time_in_ms);
  // TODO: Shall we move it to config file
  config.headers["X-App-Key"] = API_KEY;
  config.headers["X-Req-Time"] = time_in_ms;
  config.headers["X-Req-Hash"] = hashedKey;
  config.headers["Authorization"] = token;
  // }

  return config;
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response) {
      const { status, data } = error.response;

      if (status === 401) {
        localStorage.removeItem(localStorageKey.accessToken);
        window?.location?.refresh();
        // serviceWorkerRegistration.unregister();
      }
      return Promise.reject(
        (error.response && error.response.data) || "Something went wrong"
      );
    }
    return Promise.reject(error);
  }
);

axiosRetry(axiosInstance, {
  retries: 3, // number of retries
  retryDelay: (retryCount) => {
    console.log(`retry attempt: ${retryCount}`);
    return retryCount * 2000; // time interval between retries
  },
  retryCondition: (error) =>
    // if retry condition is not specified, by default idempotent requests are retried
    error.response.status === 502 ||
    error.response.status === 501 ||
    error.response.status === 500 ||
    error.response.status === 503,
});

export default axiosInstance;
