import { useEffect } from "react";
import { Container } from "@mui/material";
import queryString from "query-string";
import _ from "lodash";
import { v1 as uuidv1 } from "uuid";

import { localStorageKey } from "utils/constants/common";
import useApp from "./hooks/useApp";
import ScrollToTop from "./components/ScrollToTop";
import LoadingScreen, { ProgressBarStyle } from "./components/LoadingScreen";
import Router from "./routes";
import ThemeConfig from "./theme";
import GlobalStyles from "./theme/globalStyles";
import CommonErrorCard from "pages/components/CommonErrorCard";
import { createSearchParams, useNavigate } from "react-router-dom";
import { PATH_EXIT_FAILURE } from "routes/path";
import { whatsApp } from "./config";
import { getLocationFromIP } from "utils/common";
import useIsMountedRef from "hooks/useIsMountedRef";

const handleUserData = async (queryValues) => {
  if (_.isEmpty(queryValues?.device_id)) {
    queryValues.device_id = uuidv1();
  }
  if (_.isEmpty(queryValues?.lat)) {
    const pos = await getLocationFromIP();
    if (!_.isEmpty(pos)) {
      queryValues.lat = pos?.latitude;
      queryValues.lng = pos?.longitude;
    }
  }
  localStorage.setItem(localStorageKey.phone, queryValues?.phone);
  localStorage.setItem(localStorageKey.userInfo, JSON.stringify(queryValues));
};

function App() {
  const { isInitialized, errorParams, setErrorParams } = useApp();
  const navigate = useNavigate();
  const isMountedRef = useIsMountedRef();

  const locationQuery = window.location.search;
  const queryValues = queryString.parse(locationQuery);

  useEffect(() => {
    if (!_.isEmpty(queryValues)) {
      handleUserData(queryValues);
    }
    if (isMountedRef.current && _.isEmpty(queryValues)) {
      setErrorParams(
        "Invalid User",
        "User is not authenticated. Please try again later."
      );
    }
  }, []);

  const onBtExit = () => {
    localStorage.clear();
    // window.open("about:blank", "_self");
    // window.close();
    navigate({
      pathname: PATH_EXIT_FAILURE,
      search: createSearchParams({
        reason: errorParams?.content,
      }).toString(),
      replace: true,
    });
  };

  const onBtSupport = () => {
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    const text = "HELP MORE";
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, "_blank").focus();
  };

  return (
    <>
      <ThemeConfig>
        <ProgressBarStyle />
        <GlobalStyles />
        <CommonErrorCard
          imageSrc="/images/Error.png"
          dialogLabel={errorParams?.label}
          dialogContentText={errorParams?.content}
          buttonLabel="EXIT"
          buttonLabelClick={() => {
            onBtExit();
            setErrorParams({});
          }}
          buttonTextLabel="Contact Support"
          buttonTextLabelClick={() => {
            onBtSupport();
            setErrorParams({});
          }}
          handleCloseDialog={(event, reason) =>
            !(reason === "escapeKeyDown" || reason === "backdropClick") &&
            setErrorParams(null)
          }
          openDialog={!_.isEmpty(errorParams)}
        />
        <ScrollToTop />
        <Container maxWidth="xs" sx={{ p: 0 }}>
          {isInitialized ? <Router /> : <LoadingScreen />}
        </Container>
      </ThemeConfig>
    </>
  );
}

export default App;
