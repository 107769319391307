import React from 'react';
import PropTypes from 'prop-types';
import { Dialog, DialogContent, Box, Typography, Button } from '@mui/material';
// import ContactErrorCard from '../../../bank-transfer/components/'

CommonErrorCard.propTypes = {
  handleCloseDialog: PropTypes.func,
  openDialog: PropTypes.bool,
  imageSrc: PropTypes.string,
  dialogLabel: PropTypes.string,
  buttonLabel: PropTypes.string,
  buttonLabelClick: PropTypes.func,
  dialogContentText: PropTypes.string,
  buttonTextLabel: PropTypes.string,
  buttonTextLabelClick: PropTypes.func,
  isMultiline: PropTypes.bool,
  multiLineLabel: PropTypes.array
};

export default function CommonErrorCard(props, { sx }) {
  const {
    handleCloseDialog,
    openDialog,
    imageSrc,
    dialogLabel,
    dialogContentText,
    buttonLabel,
    buttonTextLabel,
    buttonLabelClick,
    buttonTextLabelClick,
    multiLineLabel,
    isMultiline
  } = props;
  return (
    <Dialog open={openDialog} onClose={handleCloseDialog} fullWidth>
      <DialogContent
        sx={{
          display: 'flex',
          justifyConetnt: 'center',
          alignItems: ' center',
          borderRadius: '10px',
          flexDirection: 'column',
          padding: '32px !important'
        }}
      >
        <CommonDialogContent
          sx={sx}
          imageSrc={imageSrc}
          dialogLabel={dialogLabel}
          dialogContentText={dialogContentText}
          buttonTextLabel={buttonTextLabel}
          buttonLabel={buttonLabel}
          buttonLabelClick={buttonLabelClick}
          buttonTextLabelClick={buttonTextLabelClick}
          multiLineLabel={multiLineLabel}
          isMultiline={isMultiline}
        />
      </DialogContent>
    </Dialog>
  );
}

function CommonDialogContent(props) {
  const {
    imageSrc,
    dialogLabel,
    dialogContentText,
    buttonLabel,
    buttonTextLabel,
    buttonLabelClick,
    buttonTextLabelClick,
    multiLineLabel,
    isMultiline,
    buttonTextLabelProps = {}
  } = props;
  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'space-between' }}>
      <Box
        spacing={1}
        sx={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center'
        }}
      >
        {/* <Box component="img" src="/Error.png" /> */}
        <Box component="img" src={imageSrc} />
        <Typography sx={{ fontWeight: 700, fontSize: '16px', color: '#000000', textAlign: 'center' }}>
          {dialogLabel}
        </Typography>
        {/* <Typography sx={{ fontWeight: 700, fontSize: '18px', color: '#000000' }}>Error Occurred</Typography> */}
        {!props.isMultiline ? (
          <Typography
            sx={{
              fontSize: '14px',
              color: '#919191',
              lineHeight: '20px',
              margin: '24px auto',
              fontWeight: 500,
              textAlign: 'center'
            }}
          >
            {dialogContentText}
          </Typography>
        ) : (
          <Box>
            {multiLineLabel.map((opt) => (
              <Typography
                sx={{
                  fontSize: '14px',
                  color: '#919191',
                  lineHeight: '20px',
                  margin: '24px auto',
                  fontWeight: 500,
                  textAlign: 'center'
                }}
              >
                {opt?.label}
              </Typography>
            ))}
          </Box>
        )}

        <Box />
      </Box>
      <Box>
        {buttonLabel && (
          <Button
            variant="contained"
            fullWidth
            sx={{
              height: '48px',
              boxShadow: 'none',
              fontSize: '16px',
              lineHeight: '19px',
              fontWeight: 700,
              textTransform: 'uppercase'
            }}
            onClick={buttonLabelClick}
          >
            {buttonLabel}
          </Button>
        )}
        {buttonTextLabel && (
          <Button
            variant="text"
            fullWidth
            {...buttonTextLabelProps}
            sx={{
              height: '24px',
              marginTop: '23px',
              boxShadow: 'none',
              fontSize: '14px',
              lineHeight: '19px',
              fontWeight: 700,
              color: '#2EB67D',
              textTransform: 'uppercase'
            }}
            onClick={buttonTextLabelClick}
          >
            {buttonTextLabel}
          </Button>
        )}
      </Box>
    </Box>
  );
}
