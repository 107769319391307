import { useRoutes, useLocation, Navigate } from "react-router-dom";
import { Suspense, lazy } from "react";
import PayoutLayout from "../layouts/payout-layout";
import LoadingScreen from "../components/LoadingScreen";
import LogoOnlyLayout from "../layouts/LogoOnlyLayout";
import ExitPage from "pages/ExitPage";

const Loadable = (Component) => (props) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { pathname } = useLocation();
  const isDashboard = pathname.includes("/scan");

  return (
    <Suspense
      fallback={
        <LoadingScreen
          sx={{
            ...(!isDashboard && {
              top: 0,
              left: 0,
              width: 1,
              zIndex: 9999,
              position: "fixed",
            }),
          }}
        />
      }
    >
      <Component {...props} />
    </Suspense>
  );
};

export default function Router() {
  return useRoutes([
    {
      path: "onboard",
      element: <OnboardSetupPage />,
    },
    {
      path: "processing",
      element: <OnboardingProcessPage />,
    },
    {
      path: "exit",
      children: [
        { path: "pending", element: <ExitPage /> },
        { path: "success", element: <ExitPage /> },
        { path: "failure", element: <ExitPage /> },
      ],
    },

    {
      path: "success",
      element: (
        // <AuthGuard>
        <PayoutLayout />
        // </AuthGuard>
      ),
      children: [{ path: "", element: <SuccessPage /> }],
    },

    {
      path: "payout",
      element: (
        // <AuthGuard>
        <PayoutLayout />
        // </AuthGuard>
      ),
      children: [
        { path: "processing", element: <PayoutProcessing /> },
        { path: "consent", element: <UserConsentPage /> },
      ],
    },
    {
      path: "/",
      element: <Navigate to="processing" replace />,
    },
    // Main Routes
    {
      path: "*",
      element: <LogoOnlyLayout />,
      children: [
        { path: "500", element: <Page500 /> },
        { path: "404", element: <NotFound /> },
        { path: "*", element: <Navigate to="/404" replace /> },
      ],
    },
  ]);
}

const OnboardSetupPage = Loadable(
  lazy(() => import("../pages/OnboardSetupPage"))
);
const OnboardingProcessPage = Loadable(
  lazy(() => import("../pages/OnboardingProcessPage"))
);
const PayoutProcessing = Loadable(
  lazy(() => import("../pages/PayoutProcessingPage"))
);
const UserConsentPage = Loadable(
  lazy(() => import("../pages/UserConsentPage"))
);
const SuccessPage = Loadable(lazy(() => import("../pages/SuccessPage")));

// Main Page
const NotFound = Loadable(lazy(() => import("../pages/Page404")));
const Page500 = Loadable(lazy(() => import("../pages/Page500")));
