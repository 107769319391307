import React, { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

export default function CustomSubmitButton(props) {
  const { isLoading, label, onClick, sx, ...others } = props;

  return (
    <LoadingButton
      loading={isLoading}
      onClick={onClick}
      {...others}
      sx={{
        textTransform: "uppercase",
        boxShadow: "0px 2px 4px rgba( 0 0 0 / 35%)",
        height: "48px",
        border: "0px",
        fontSize: "16px",
        ...sx,
      }}
    >
      {label}
    </LoadingButton>
  );
}
