import React, { useState } from "react";
import { IconButton, MenuItem, Menu } from "@mui/material";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { whatsApp } from "../../config";
import { localStorageKey } from "utils/constants/common";

export default function HelpIcon() {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  
  const onMenuBt = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const onBtSupport = () => {
    const phone = localStorage.getItem(localStorageKey.phone);
    let whatsAppUrl = `${whatsApp.url}/${whatsApp.number}`;
    const text = 'HELP MORE';
    whatsAppUrl = whatsAppUrl.concat(`?text=${encodeURI(`${text}\n${phone}`)}`);
    window.open(whatsAppUrl, '_blank').focus();
  };
  return (
    <>
      <Menu
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
        anchorEl={anchorEl}
        open={open}
        dense
        onClose={handleMenuClose}
        // anchorOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
        // transformOrigin={{
        //   vertical: 'top',
        //   horizontal: 'left'
        // }}
      >
        <MenuItem onClick={onBtSupport}>Contact Support </MenuItem>
      </Menu>
      <IconButton
        edge="start"
        aria-label="help"
        sx={{ color: "black", }}
        onClick={onMenuBt}
      >
        <HelpOutlineIcon />
      </IconButton>
    </>
  );
}
