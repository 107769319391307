import { styled, alpha } from '@mui/material/styles';
import { Paper } from '@mui/material';

const PaperBox = styled(Paper)(({ theme }) => ({
  paddingLeft: 16,
  paddingRight: 16,
  backgroundColor: 'white',
  boxShadow: '0 0 19px 0 rgba(0,0,0,0.1)',
  borderRadius: '9px',
}));

export default PaperBox;
